<template>
  <div class="integral-container">
    <!-- 积分签到模块 -->
    <div class="col-center integral-signin-container">
      <p class="integral-signin-title">积分</p>
      <p class="integral-signin-content">{{ rewardListInfo.integral }}</p>
      <div class="integral-signin col-center">
        <div
          style="width:100%;"
          :class="rewardListWeek.length > 7 ? 'row-evenly-integral' : 'row-evenly'"
          class="integral-signin-item-list"
        >
          <div
            class="integral-signin-item col-center"
            :style="rewardListWeek.length > 7 ? 'margin-right:15px;' : ''"
            v-for="(i,index) in rewardListWeek"
            :key="index"
          >
            <div
              class="row-center"
              :class="(rewardListInfo.signinDay > i.day) && (i.isCheck != 1) ? 'integral-not-signin-item-num' : 'integral-signin-item-num'"
            >
              +{{ i.integral }}
              <img
                v-if="i.isCheck == 1"
                src="../../assets/icon/sign-in-right.png"
              />
            </div>
            <p class="integral-signin-item-day">{{ i.isCheck == 1 ? '已签' : i.day + '天' }}</p>
          </div>
        </div>
        <div
          v-if="!rewardListInfo.checkToday"
          @click="signInSummit"
          class="row-center integral-signin-button"
        >
          签到
        </div>
        <div
          v-if="rewardListInfo.checkToday"
          class="row-center integral-signin-used-button"
        >
          今日已签到
        </div>
      </div>
    </div>
    <!-- 积分出入 -->
    <div class="integral-detail-container">
      <div
        style="width: 100%;background: #1c1c1c;left: 0;z-index: 200;position: initial;"
        class="order-menu"
      >
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">
            获取
          </el-menu-item>
          <el-menu-item index="2">
            支出
          </el-menu-item>
          <el-menu-item index="0">
            7天后过期积分
          </el-menu-item>
        </el-menu>
      </div>
      <div
        ref="integralBox"
        class="integral-detail"
      >
        <van-list
          v-if="!noOrder"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="load"
        >
          <div
            class="order-item row-between"
            v-for="(item, index) in rewardList"
            :key="index"
          >
            <div class="order-item-left">
              <p class="order-item-left-name">{{item.integralDetail}}</p>
              <p class="order-item-left-time">{{activeIndex == '1' || activeIndex == '0' ? '获取时间' : '使用时间'}} {{item.createTime}}</p>
            </div>
            <p class="order-item-right">{{activeIndex == '1' || activeIndex == '0' ? '+' : ''}}{{item.amount}}</p>
          </div>
        </van-list>
        <div
          v-if="noOrder"
          class="col-center"
          style="width:100%;height: calc(100vh - 24rem);"
        >
          <img
            style="width:6.5rem;height:auto;margin-bottom:1.375rem;"
            :src="require('../../assets/icon/no-list.png')"
          />
          <p style="font-size: 16px;font-family: lantingheiweight;font-weight: 500;color: #666666;line-height: 22px;">暂无数据</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '../../api/index-client'
import Vue from 'vue'
import { Menu,Button,MenuItem } from 'element-ui';
import { List,Popup } from 'vant';
Vue.use(List);
Vue.use(Popup);
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Button)
export default {
  data () {
    return {
      loading: false,
      finished: false,
      noOrder: false,
      rewardListInfo: {
        integral: '',
        signinDay: '',
        checkToday: true,
      },
      rewardListWeek: [],
      rewardListType: 1,
      rewardList: [],
      activeIndex: '1',
      index: 1,
      indexGoodMore: true,
      indexLoad: true,
      listParam: {
        page: 0,
        limit: 10
      }
    }
  },
  computed: {
    integralBox () {
      return this.$refs.integralBox;
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.integralBox.addEventListener("scroll",this.handleScroll);
    });
    this.signInDetail()
  },
  methods: {
    handleScroll () {
      //获取dom滚动距离
      const scrollTop = this.integralBox.scrollTop;
      //获取可视区高度
      const offsetHeight = this.integralBox.offsetHeight;
      //获取滚动条总高度
      const scrollHeight = this.integralBox.scrollHeight;
      if (scrollTop + offsetHeight >= scrollHeight) {
        // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
        setTimeout(() => {
          this.indexGoodMore = true
          this.load()
        },500)

      }

    },
    // tab切换
    handleSelect (key,keyPath) {
      this.listParam.page = 0
      this.loading = true
      this.rewardList = []
      this.activeIndex = key
      this.indexGoodMore = true
      this.indexLoad = true
      this.finished = false
      this.load()
    },
    // 签到
    signInSummit () {
      api
        .post('signIn/summit')
        .then(result => {
          if (result.data.success) {
            this.$toast({
              message: '签到成功',
              icon: require('../../assets/icon/toast-success.png'),
            });
            this.$store.commit('SHOW_APPLOADING')
            this.signInDetail()
            // 获取积分记录
            this.getIntegralList(this.activeIndex)
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 签到明细
    signInDetail () {
      api
        .get('signIn/detail')
        .then(result => {
          if (result.data.success) {
            this.rewardListInfo = result.data.data
            this.rewardListWeek = result.data.data.rewardList
            this.$store.commit('HIDE_APPLOADING')
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    load () {
      if (this.indexGoodMore && !this.finished && this.indexLoad) {
        this.listParam.page += 1
        this.loading = true
        this.getIntegralList(this.activeIndex)
        this.indexGoodMore = false
      }
    },
    // 获取积分记录
    getIntegralList (e) {
      this.indexLoad = false
      api
        .get('integral/list?type=' + e + '&page=' + this.listParam.page + '&limit=' + this.listParam.limit)
        .then(result => {
          if (result.data.success) {
            // 获取积分记录
            result.data.data.map(i => {
              this.rewardList.push(i)
            })
            this.noOrder = this.rewardList.length == 0
            if (result.data.data.length < this.listParam.limit) {
              this.finished = true
            } else {
              this.finished = false
            }
            this.indexLoad = true
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
            this.indexLoad = true
          }
        })
      this.loading = false
    }
  },
}
</script>
<style lang="less" scoped>
.integral-container {
  margin-top: 3.875rem;
  height: calc(100vh - 3.875rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .integral-signin-container {
    width: 94vw;
    margin-left: 3vw;
    border-radius: 4px;
    height: 14.25rem;
    background: #262728;
    .integral-signin-title {
      font-size: 15px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #f0f0f0;
    }
    .integral-signin-content {
      font-size: 24px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #f0f0f0;
      margin-top: 5px;
    }
  }
  .integral-signin {
    width: 96%;
    height: 8.875rem;
    margin-top: 10px;
    .integral-signin-item {
      width: 9vw;
      height: auto;
      .integral-signin-item-num {
        width: 9vw;
        height: 9vw;
        border-radius: 2rem;
        background: #3059ad;
        font-size: 13px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #a9c5ff;
        position: relative;
        img {
          position: absolute;
          bottom: -5px;
          width: 13px;
          height: auto;
        }
      }
      .integral-not-signin-item-num {
        width: 9vw;
        height: 9vw;
        border-radius: 2rem;
        background: #4b4d4f;
        font-size: 13px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #898a8b;
        position: relative;
      }
      .integral-signin-item-day {
        margin-top: 12px;
        font-size: 13px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #f0f0f0;
      }
    }
    .integral-signin-button {
      width: 180px;
      height: 35px;
      background-image: url('../../assets/icon/confirm-button.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-color: transparent;
      font-size: 15px;
      font-family: lantingheiweight;
      font-weight: normal;
      color: #f0f0f0;
      line-height: 1.375rem;
      letter-spacing: 2px;
      cursor: pointer;
      background-size: cover;
      margin-top: 16px;
    }
    .integral-signin-used-button {
      width: 180px;
      height: 35px;
      background-image: url('../../assets/icon/open-soon.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: 0% 0%;
      background-color: transparent;
      font-size: 15px;
      font-family: lantingheiweight;
      font-weight: normal;
      color: #f0f0f0;
      line-height: 1.375rem;
      letter-spacing: 2px;
      background-size: cover;
      margin-top: 16px;
    }
  }
  .integral-detail-container {
    width: 94vw;
    height: calc(100vh - 20rem);
    margin-left: 3vw;
    border-radius: 4px;
    margin-top: 20px;
    background: #262728;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background: #262728 !important;
    background-color: #262728 !important;
  }
  .el-menu {
    background: #262728 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 4px 4px 0 0;
    .el-menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  /deep/.el-menu--horizontal > .el-menu-item.is-active {
    color: #739ef6 !important;
  }
  .integral-detail {
    width: 100%;
    height: calc(100vh - 24rem);
    overflow-y: auto;
  }
  .order-item {
    width: 94%;
    margin-left: 3%;
    height: 84px;
    border: 1px solid #4f5153;
    border-radius: 0;
    .order-item-left {
      margin-left: 18px;
      .order-item-left-name {
        font-size: 14px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
      }
      .order-item-left-time {
        margin-top: 19px;
        font-size: 12px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #828385;
      }
    }
    .order-item-right {
      margin-right: 25px;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .row-evenly-integral {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .integral-signin-item-list {
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
</style>